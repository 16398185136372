<template>
  <div class="content_body ReportEntitySaleDetail " style="padding:0px" v-loading="loading">
    <el-tabs type="border-card">
      <el-tab-pane label="销售明细">
        <div class="nav_header" style="padding:0px">
          <el-form :inline="true" size="small" :model="searchSaleData" @submit.native.prevent>
            <el-form-item v-if="storeEntityList.length > 1" label="开单门店">
              <el-select v-model="searchSaleData.EntityID" clearable filterable placeholder="请选择门店" :default-first-option="true" @change="handleSaleSearch">
                <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间筛选">
              <el-date-picker v-model="searchSaleData.QueryDate" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSaleSearch"></el-date-picker>
            </el-form-item>
            <el-form-item label="建档时间筛选">
              <el-date-picker v-model="searchSaleData.CreateDate" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSaleSearch"></el-date-picker>
            </el-form-item>
            <el-form-item label="会员来源">
              <el-cascader
                  v-model="searchSaleData.CustomerSourceID"
                  :options="customerSource"
                  :props="{
                  checkStrictly: true,
                  children: 'Child',
                  value: 'ID',
                  label: 'Name',
                  emitPath: false,
                }"
                  :show-all-levels="false"
                  clearable
                  filterable
                  @change="handleSaleSearch"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="商品类型">
              <el-select v-model="searchSaleData.GoodsTypeName" clearable filterable placeholder="请选择商品类型" :default-first-option="true" @change="handleSaleSearch">
                <el-option label="项目" value="项目"></el-option>
                <el-option label="储值卡" value="储值卡"></el-option>
                <el-option label="时效卡" value="时效卡"></el-option>
                <el-option label="通用次卡" value="通用次卡"></el-option>
                <el-option label="套餐卡" value="套餐卡"></el-option>
                <el-option label="产品" value="产品"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input v-model="searchSaleData.GoodName" clearable @keyup.enter.native="handleSaleSearch" @clear="handleSaleSearch" placeholder="请输入商品名称"></el-input>
            </el-form-item>
            <el-form-item label="客户">
              <el-input v-model="searchSaleData.CustomerName" clearable @keyup.enter.native="handleSaleSearch" @clear="handleSaleSearch" placeholder="请输入姓名/手机号/客户编号搜索"></el-input>
            </el-form-item>
            <!-- <el-form-item label="是否赠送">
              <el-select v-model="searchSaleData.IsLargess" @change="handleSaleSearch" @clear="handleSaleSearch" placeholder="请选择是否赠送" clearable>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" v-prevent-click @click="handleSaleSearch">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button v-if="SaleDetailExport" type="primary" size="small" :loading="downloadLoading" @click="downloadSaleExcel">导出</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-table size="small" show-summary :summary-method="getsaleDetailListSummaries" :data="saleDetailList" v-loading="saleDetailLoading">
          <el-table-column prop="BillID" label="订单编号"></el-table-column>
          <el-table-column prop="BillDate" label="下单日期"></el-table-column>
          <el-table-column prop="EntityName" label="下单门店"></el-table-column>
          <el-table-column prop="CustomerName" width="150px" label="客户">
            <template slot-scope="scope">
              <div>
                {{ scope.row.CustomerName }} <span v-if="scope.row.CustomerCode">({{ scope.row.CustomerCode }})</span>
              </div>
              <div v-if="scope.row.CustomerPhoneNumber">手机号：{{ scope.row.CustomerPhoneNumber }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="CustomerSourceName" label="客户来源"></el-table-column>
          <el-table-column prop="CreatedOn" label="建档时间"></el-table-column>
          <el-table-column prop="EmployeeName" label="经手人"></el-table-column>
          <el-table-column prop="GoodsTypeName" label="商品类型"></el-table-column>
          <el-table-column prop="CategoryName" label="商品类别"></el-table-column>
          <el-table-column prop="BuyProperty" label="购买属性"></el-table-column>
          <el-table-column prop="GoodName" label="商品名"></el-table-column>
          <el-table-column align="right" prop="Price" label="单价">
            <template slot-scope="scope">
              {{ scope.row.Price | NumFormat }}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="Quantity" label="数量"></el-table-column>
          <el-table-column align="right" prop="IsLargess" label="是否赠送">
            <template slot-scope="scope">
              <div v-if="scope.row.IsLargess">是</div>
              <div v-else>否</div>
            </template>
          </el-table-column>
          <!-- <el-table-column align="right" prop="PreferentialAmount" label="优惠金额">
            <template align="right" slot-scope="scope">
              <div v-if="scope.row.PreferentialAmount<0" class="color_red">{{scope.row.PreferentialAmount | NumFormat}}</div>
              <div v-else-if="scope.row.PreferentialAmount>0" class="color_green">+{{scope.row.PreferentialAmount | NumFormat}}</div>
              <div v-else>0.00</div>
            </template>
          </el-table-column> -->
          <el-table-column align="right" prop="TotalAmount" label="合计金额">
            <template slot-scope="scope">
              <span class="font_weight_600">{{ scope.row.TotalAmount | NumFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column align="right" prop="ArrearAmount" label="欠款金额">
            <template slot-scope="scope">
              {{ scope.row.ArrearAmount | NumFormat }}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="PayAmount" label="实收金额">
            <template slot-scope="scope">
              {{ scope.row.PayAmount | NumFormat }}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="SavingCardDeductionAmount" label="卡抵扣">
            <template slot-scope="scope">
              {{ scope.row.SavingCardDeductionAmount | NumFormat }}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="LargessSavingCardDeductionAmount" label="赠卡抵扣">
            <template slot-scope="scope">
              {{ scope.row.LargessSavingCardDeductionAmount | NumFormat }}
            </template>
          </el-table-column>
        </el-table>
        <div class="pad_15 text_right">
          <el-pagination background v-if="salePaginations.total > 0" @current-change="handleSaleDetailPageChange" :current-page.sync="salePaginations.page" :page-size="salePaginations.page_size" :layout="salePaginations.layout" :total="salePaginations.total"></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="补尾款明细">
        <div class="nav_header" style="padding:0px">
          <el-form :inline="true" size="small" :model="searchSaleArrearData" @submit.native.prevent>
            <el-form-item v-if="storeEntityList.length > 1" label="开单门店">
              <el-select v-model="searchSaleArrearData.EntityID" clearable filterable placeholder="请选择门店" :default-first-option="true" @change="handleSaleArrearSearch">
                <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间筛选">
              <el-date-picker v-model="searchSaleArrearData.QueryDate" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSaleArrearSearch"></el-date-picker>
            </el-form-item>
            <el-form-item label="建档时间筛选">
              <el-date-picker v-model="searchSaleData.CreateDate" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSaleSearch"></el-date-picker>
            </el-form-item>
            <el-form-item label="会员来源">
              <el-cascader
                  v-model="searchSaleArrearData.CustomerSourceID"
                  :options="customerSource"
                  :props="{
                  checkStrictly: true,
                  children: 'Child',
                  value: 'ID',
                  label: 'Name',
                  emitPath: false,
                }"
                  :show-all-levels="false"
                  clearable
                  filterable
                  @change="handleSaleArrearSearch"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="商品类型">
              <el-select v-model="searchSaleArrearData.GoodsTypeName" clearable filterable placeholder="请选择商品类型" :default-first-option="true" @change="handleSaleArrearSearch">
                <el-option label="项目" value="项目"></el-option>
                <el-option label="储值卡" value="储值卡"></el-option>
                <el-option label="时效卡" value="时效卡"></el-option>
                <el-option label="通用次卡" value="通用次卡"></el-option>
                <el-option label="套餐卡" value="套餐卡"></el-option>
                <el-option label="产品" value="产品"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input v-model="searchSaleArrearData.GoodName" clearable @keyup.enter.native="handleSaleArrearSearch" @clear="handleSaleArrearSearch" placeholder="请输入商品名称"></el-input>
            </el-form-item>
            <el-form-item label="客户">
              <el-input v-model="searchSaleArrearData.CustomerName" clearable @keyup.enter.native="handleSaleArrearSearch" @clear="handleSaleArrearSearch" placeholder="请输入姓名/手机号/客户编号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" v-prevent-click @click="handleSaleArrearSearch">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button v-if="ArrearSaleDetailExport" type="primary" size="small" :loading="downloadLoading" @click="downloadSaleArrearExcel">导出</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-table size="small" show-summary :summary-method="getsaleArrearDetailListSummaries" :data="saleArrearDetailList" v-loading="saleArrearDetailLoading">
          <el-table-column prop="BillID" label="订单编号"></el-table-column>
          <el-table-column prop="BillDate" label="下单日期"></el-table-column>
          <el-table-column prop="EntityName" label="下单门店"></el-table-column>
          <el-table-column prop="CustomerName" width="150px" label="客户">
            <template slot-scope="scope">
              <div>
                <span class="marrt_10">{{ scope.row.CustomerName }}</span
                ><span v-if="scope.row.CustomerCode">({{ scope.row.CustomerCode }})</span>
              </div>
              <div v-if="scope.row.CustomerPhoneNumber">手机号：{{ scope.row.CustomerPhoneNumber }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="CustomerSourceName" label="客户来源"></el-table-column>
          <el-table-column prop="CreatedOn" label="建档时间"></el-table-column>
          <el-table-column prop="EmployeeName" label="开单人"></el-table-column>
          <el-table-column prop="GoodsTypeName" label="商品类型"></el-table-column>
          <el-table-column prop="CategoryName" label="商品类别"></el-table-column>
          <el-table-column prop="GoodName" label="商品名"></el-table-column>
          <el-table-column align="right" prop="BuyAmount" label="购买金额">
            <template slot-scope="scope">
              {{ scope.row.BuyAmount | NumFormat }}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="TotalAmount" label="补尾款金额">
            <template slot-scope="scope">
              <span class="font_weight_600">{{ scope.row.TotalAmount | NumFormat }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pad_15 text_right">
          <el-pagination
            background
            v-if="saleArrearPaginations.total > 0"
            @current-change="handleSaleArrearDetailPageChange"
            :current-page.sync="saleArrearPaginations.page"
            :page-size="saleArrearPaginations.page_size"
            :layout="saleArrearPaginations.layout"
            :total="saleArrearPaginations.total"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="退款明细">
        <div class="nav_header" style="padding:0px">
          <el-form :inline="true" size="small" :model="searchSaleRefundData" @submit.native.prevent>
            <el-form-item v-if="storeEntityList.length > 1" label="开单门店">
              <el-select v-model="searchSaleRefundData.EntityID" clearable filterable placeholder="请选择门店" :default-first-option="true" @change="handleSaleRefundSearch">
                <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间筛选">
              <el-date-picker v-model="searchSaleRefundData.QueryDate" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSaleRefundSearch"></el-date-picker>
            </el-form-item>
            <el-form-item label="建档时间筛选">
              <el-date-picker v-model="searchSaleData.CreateDate" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSaleSearch"></el-date-picker>
            </el-form-item>
            <el-form-item label="会员来源">
              <el-cascader
                  v-model="searchSaleRefundData.CustomerSourceID"
                  :options="customerSource"
                  :props="{
                  checkStrictly: true,
                  children: 'Child',
                  value: 'ID',
                  label: 'Name',
                  emitPath: false,
                }"
                  :show-all-levels="false"
                  clearable
                  filterable
                  @change="handleSaleRefundSearch"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="商品类型">
              <el-select v-model="searchSaleRefundData.GoodsTypeName" clearable filterable placeholder="请选择商品类型" :default-first-option="true" @change="handleSaleRefundSearch">
                <el-option label="项目" value="项目"></el-option>
                <el-option label="储值卡" value="储值卡"></el-option>
                <el-option label="时效卡" value="时效卡"></el-option>
                <el-option label="通用次卡" value="通用次卡"></el-option>
                <el-option label="套餐卡" value="套餐卡"></el-option>
                <el-option label="产品" value="产品"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input v-model="searchSaleRefundData.GoodName" clearable @keyup.enter.native="handleSaleRefundSearch" @clear="handleSaleRefundSearch" placeholder="请输入商品名称"></el-input>
            </el-form-item>
            <el-form-item label="客户">
              <el-input v-model="searchSaleRefundData.CustomerName" clearable @keyup.enter.native="handleSaleRefundSearch" @clear="handleSaleRefundSearch" placeholder="请输入姓名/手机号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" v-prevent-click @click="handleSaleRefundSearch">搜索</el-button>
            </el-form-item>

            <el-form-item>
              <el-button v-if="RefundSaleDetailExport" type="primary" size="small" :loading="downloadLoading" @click="downloadSaleRefundExcel">导出</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-table size="small" show-summary :summary-method="getsaleRefundDetailListSummaries" :data="saleRefundDetailList" v-loading="saleRefundDetailLoading">
          <el-table-column prop="BillID" label="订单编号"></el-table-column>
          <el-table-column prop="BillDate" label="下单日期"></el-table-column>
          <el-table-column prop="EntityName" label="下单门店"></el-table-column>
          <el-table-column prop="CustomerName" width="150px" label="客户">
            <template slot-scope="scope">
              <div>
                {{ scope.row.CustomerName }} <span v-if="scope.row.CustomerCode">({{ scope.row.CustomerCode }})</span>
              </div>
              <div v-if="scope.row.CustomerPhoneNumber">手机号：{{ scope.row.CustomerPhoneNumber }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="CustomerSourceName" label="客户来源"></el-table-column>
          <el-table-column prop="CreatedOn" label="建档时间"></el-table-column>
          <el-table-column prop="EmployeeName" label="开单人"></el-table-column>
          <el-table-column prop="GoodsTypeName" label="商品类型"></el-table-column>
          <el-table-column prop="CategoryName" label="商品类别"></el-table-column>
          <el-table-column prop="GoodName" label="商品名"></el-table-column>
          <el-table-column align="right" prop="Quantity" label="数量"></el-table-column>
          <el-table-column align="right" prop="IsLargess" label="是否赠送">
            <template slot-scope="scope">
              <div v-if="scope.row.IsLargess">是</div>
              <div v-else>否</div>
            </template>
          </el-table-column>
          <el-table-column align="right" prop="OriginAmount" label="可退金额">
            <template slot-scope="scope">
              {{ scope.row.OriginAmount | NumFormat }}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="TotalAmount" label="实际退金额">
            <template slot-scope="scope">
              <span class="font_weight_600">{{ scope.row.TotalAmount | NumFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column align="right" prop="PayAmount" label="退款金额">
            <template slot-scope="scope">
              {{ scope.row.PayAmount | NumFormat }}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="SavingCardDeductionAmount" label="退回卡金额">
            <template slot-scope="scope">
              {{ scope.row.SavingCardDeductionAmount | NumFormat }}
            </template>
          </el-table-column>
          <el-table-column align="right" prop="LargessSavingCardDeductionAmount" label="退回赠送卡金额">
            <template slot-scope="scope">
              {{ scope.row.LargessSavingCardDeductionAmount | NumFormat }}
            </template>
          </el-table-column>
        </el-table>
        <div class="pad_15 text_right">
          <el-pagination
            background
            v-if="saleRefundPaginations.total > 0"
            @current-change="handleSaleRefundDetailPageChange"
            :current-page.sync="saleRefundPaginations.page"
            :page-size="saleRefundPaginations.page_size"
            :layout="saleRefundPaginations.layout"
            :total="saleRefundPaginations.total"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import EntityAPI from "@/api/Report/Common/entity";
import API from "@/api/Report/Entity/saleDetailStatement";
import permission from "@/components/js/permission.js";
import APICustomerSource from "@/api/CRM/Customer/customerSource";

export default {
  name: "ReportEntitySaleDetail",
  data() {
    return {
      loading: false,
      downloadLoading: false,
      customerSource: [], //顾客来源
      searchSaleData: {
        EntityID: null,
        QueryDate: [new Date(), new Date()],
        CreateDate: null,
        GoodsTypeName: "",
        GoodName: "",
        IsLargess: null,
        CustomerSourceID: null,
        CustomerName: null,
        CustomerCode: null,
      },
      searchSaleArrearData: {
        EntityID: null,
        CustomerSourceID: null,
        QueryDate: [new Date(), new Date()],
        CreateDate: null,
        GoodsTypeName: "",
        GoodName: "",
        IsLargess: null,
        CustomerName: null,
        CustomerCode: null,
      },
      searchSaleRefundData: {
        EntityID: null,
        CustomerSourceID: null,
        QueryDate: [new Date(), new Date()],
        CreateDate: null,
        GoodsTypeName: "",
        GoodName: "",
        IsLargess: null,
        CustomerName: null,
        CustomerCode: null,
      },
      storeEntityList: [], //门店列表
      saleDetailList: [], //销售明细
      saleDetailSum: {},
      saleDetailLoading: false,
      saleArrearDetailList: [], //销售不欠款明细
      saleArrearDetailSum: {},
      saleArrearDetailLoading: false,
      saleRefundDetailList: [], //销售不欠款明细
      saleRefundDetailSum: {},
      saleRefundDetailLoading: false,
      //需要给分页组件传的信息
      salePaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      saleArrearPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      saleRefundPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      ArrearSaleDetailExport: false,
      RefundSaleDetailExport: false,
      SaleDetailExport: false,
    };
  },
  methods: {
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.ArrearSaleDetailExport = permission.permission(to.meta.Permission, "Report-Entity-ArrearSaleDetail-Export");
        vm.RefundSaleDetailExport = permission.permission(to.meta.Permission, "Report-Entity-RefundSaleDetail-Export");
        vm.SaleDetailExport = permission.permission(to.meta.Permission, "Report-Entity-SaleDetail-Export");
      });
    },
    // 顾客来源
    CustomerSourceData: function() {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICustomerSource.getCustomerSource(params)
          .then((res) => {
            console.log(res);
            if (res.StateCode == 200) {
              that.customerSource = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function() {
            that.loading = false;
          });
    },
    //获得当前用户下的权限门店
    getstoreEntityList() {
      var that = this;
      that.loading = true;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    handleSaleSearch() {
      var that = this;
      that.salePaginations.page = 1;
      that.SaleSearch();
    },
    handleSaleDetailPageChange(page) {
      this.salePaginations.page = page;
      this.SaleSearch();
    },
    // 销售搜索
    SaleSearch() {
      var that = this;
      if (that.searchSaleData.QueryDate != null) {
        var params = {
          EntityID: that.searchSaleData.EntityID,
          CustomerSourceID: that.searchSaleData.CustomerSourceID,
          StartDate: that.searchSaleData.QueryDate[0],
          EndDate: that.searchSaleData.QueryDate[1],
          CreateStartDate: that.searchSaleData.CreateDate != null ? that.searchSaleData.CreateDate[0] : null,
          CreateEndDate: that.searchSaleData.CreateDate != null ? that.searchSaleData.CreateDate[1] : null,
          GoodsTypeName: that.searchSaleData.GoodsTypeName.trim(),
          GoodName: that.searchSaleData.GoodName.trim(),
          IsLargess: that.searchSaleData.IsLargess,
          PageNum: that.salePaginations.page,
          CustomerName: that.searchSaleData.CustomerName,
          CustomerCode: that.searchSaleData.CustomerCode,
        };
        that.saleDetailLoading = true;
        API.getSaleDetailStatement(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.saleDetailSum = res.Data.entitySaleDetailSumStatementForm;
              that.saleDetailList = res.Data.entitySaleDetailStatementForms.List;
              that.salePaginations.total = res.Data.entitySaleDetailStatementForms.Total;
              that.salePaginations.page_size = res.Data.entitySaleDetailStatementForms.PageSize;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function() {
            that.saleDetailLoading = false;
          });
      }
      // else{
      //   that.$message.error({
      //     message:"请选择查询时间",
      //     duration:2000
      //   })
      // }
    },
    getsaleDetailListSummaries(param) {
      // const { columns, data } = param;
      const sums = [];
      sums[0] = <span class="font_weight_600">合计</span>;
      sums[1] = "";
      sums[2] = "";
      sums[3] = "";
      sums[4] = "";
      sums[5] = "";
      sums[6] = "";
      sums[7] = "";
      sums[8] = "";
      sums[9] = "";
      sums[10] = "";
      sums[11] = "";
      sums[12] = "";
      sums[13] = "";
      sums[14] = "";
      if (!this.saleDetailSum) {
        sums[14] = "";
        sums[15] = "";
        sums[16] = "";
        sums[17] = "";
        sums[18] = "";
        return sums;
      }
      var filter_NumFormat = this.$options.filters["NumFormat"];
      sums[14] = <span class="font_weight_600">{filter_NumFormat(this.saleDetailSum.TotalAmount)}</span>;
      sums[15] = <span class="font_weight_600">{filter_NumFormat(this.saleDetailSum.ArrearAmount)}</span>;
      sums[16] = <span class="font_weight_600">{filter_NumFormat(this.saleDetailSum.PayAmount)}</span>;
      sums[17] = <span class="font_weight_600">{filter_NumFormat(this.saleDetailSum.SavingCardDeductionAmount)}</span>;
      sums[18] = <span class="font_weight_600">{filter_NumFormat(this.saleDetailSum.LargessSavingCardDeductionAmount)}</span>;
      return sums;
    },
    handleSaleArrearSearch() {
      var that = this;
      that.saleArrearPaginations.page = 1;
      that.SaleArrearSearch();
    },
    handleSaleArrearDetailPageChange(page) {
      this.saleArrearPaginations.page = page;
      this.SaleArrearSearch();
    },
    // 销售欠款搜索
    SaleArrearSearch() {
      var that = this;
      if (that.searchSaleArrearData.QueryDate != null) {
        var params = {
          EntityID: that.searchSaleArrearData.EntityID,
          CustomerSourceID: that.searchSaleArrearData.CustomerSourceID,
          StartDate: that.searchSaleArrearData.QueryDate[0],
          // StartDate: "2020-03-02",
          EndDate: that.searchSaleArrearData.QueryDate[1],
          CreateStartDate: that.searchSaleData.CreateDate != null ? that.searchSaleData.CreateDate[0] : null,
          CreateEndDate: that.searchSaleData.CreateDate != null ? that.searchSaleData.CreateDate[1] : null,
          GoodsTypeName: that.searchSaleArrearData.GoodsTypeName.trim(),
          GoodName: that.searchSaleArrearData.GoodName.trim(),
          IsLargess: that.searchSaleArrearData.IsLargess,
          PageNum: that.saleArrearPaginations.page,
          CustomerName: that.searchSaleArrearData.CustomerName,
          CustomerCode: that.searchSaleArrearData.CustomerCode,
        };
        that.saleArrearDetailLoading = true;
        API.getSaleArrearDetailStatement(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.saleArrearDetailSum = res.Data.entitySaleArrearDetailSumStatementForm;
              that.saleArrearDetailList = res.Data.entitySaleArrearDetailStatementForm.List;
              that.saleArrearPaginations.total = res.Data.entitySaleArrearDetailStatementForm.Total;
              that.saleArrearPaginations.page_size = res.Data.entitySaleArrearDetailStatementForm.PageSize;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function() {
            that.saleArrearDetailLoading = false;
          });
      }
    },
    getsaleArrearDetailListSummaries() {
      const sums = [];
      sums[0] = <span class="font_weight_600">合计</span>;
      sums[1] = "";
      sums[2] = "";
      sums[3] = "";
      sums[4] = "";
      sums[5] = "";
      sums[6] = "";
      sums[7] = "";
      sums[8] = "";
      sums[9] = "";
      sums[10] = "";
      if (!this.saleArrearDetailSum) {
        sums[11] = "";
        return sums;
      }
      var filter_NumFormat = this.$options.filters["NumFormat"];
      sums[11] = <span class="font_weight_600">{filter_NumFormat(this.saleArrearDetailSum.TotalAmount)}</span>;
      return sums;
    },
    handleSaleRefundSearch() {
      var that = this;
      that.saleRefundPaginations.page = 1;
      that.SaleRefundSearch();
    },
    handleSaleRefundDetailPageChange(page) {
      this.saleRefundPaginations.page = page;
      this.SaleRefundSearch();
    },
    // 销售退款搜索
    SaleRefundSearch() {
      var that = this;
      if (that.searchSaleRefundData.QueryDate != null) {
        var params = {
          EntityID: that.searchSaleRefundData.EntityID,
          CustomerSourceID: that.searchSaleRefundData.CustomerSourceID,
          StartDate: that.searchSaleRefundData.QueryDate[0],
          EndDate: that.searchSaleRefundData.QueryDate[1],
          CreateStartDate: that.searchSaleData.CreateDate != null ? that.searchSaleData.CreateDate[0] : null,
          CreateEndDate: that.searchSaleData.CreateDate != null ? that.searchSaleData.CreateDate[1] : null,
          GoodsTypeName: that.searchSaleRefundData.GoodsTypeName.trim(),
          GoodName: that.searchSaleRefundData.GoodName.trim(),
          IsLargess: that.searchSaleRefundData.IsLargess,
          PageNum: that.saleRefundPaginations.page,
          CustomerName: that.searchSaleRefundData.CustomerName,
          CustomerCode: that.searchSaleArrearData.CustomerCode,
        };
        that.saleRefundDetailLoading = true;
        API.getSaleRefundDetailStatement(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.saleRefundDetailList = res.List;
              that.saleRefundPaginations.total = res.Total;
              that.saleRefundPaginations.page_size = res.PageSize;

              that.saleRefundDetailSum = res.Data.entitySaleRefundDetailSumStatementForm;
              that.saleRefundDetailList = res.Data.entitySaleRefundDetailStatementForms.List;
              that.saleRefundPaginations.total = res.Data.entitySaleRefundDetailStatementForms.Total;
              that.saleRefundPaginations.page_size = res.Data.entitySaleRefundDetailStatementForms.PageSize;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function() {
            that.saleRefundDetailLoading = false;
          });
      }
      // else{
      //   that.$message.error({
      //     message:"请选择查询时间",
      //     duration:2000
      //   })
      // }
    },
    getsaleRefundDetailListSummaries() {
      const sums = [];
      sums[0] = <span class="font_weight_600">合计</span>;
      sums[1] = "";
      sums[2] = "";
      sums[3] = "";
      sums[4] = "";
      sums[5] = "";
      sums[6] = "";
      sums[7] = "";
      sums[8] = "";
      sums[9] = "";
      sums[10] = "";
      sums[11] = "";
      if (!this.saleRefundDetailSum) {
        sums[12] = "";
        sums[13] = "";
        sums[14] = "";
        sums[15] = "";
        sums[16] = "";
        return sums;
      }
      var filter_NumFormat = this.$options.filters["NumFormat"];
      sums[12] = <span class="font_weight_600">{filter_NumFormat(this.saleRefundDetailSum.OriginAmount)}</span>;
      sums[13] = <span class="font_weight_600">{filter_NumFormat(this.saleRefundDetailSum.TotalAmount)}</span>;
      sums[14] = <span class="font_weight_600">{filter_NumFormat(this.saleRefundDetailSum.PayAmount)}</span>;
      sums[15] = <span class="font_weight_600">{filter_NumFormat(this.saleRefundDetailSum.SavingCardDeductionAmount)}</span>;
      sums[16] = <span class="font_weight_600">{filter_NumFormat(this.saleRefundDetailSum.LargessSavingCardDeductionAmount)}</span>;
      return sums;
    },

    /** 数据导出 销售 */
    downloadSaleExcel() {
      var that = this;
      if (that.searchSaleData.QueryDate != null) {
        let params = {
          EntityID: that.searchSaleData.EntityID,
          CustomerSourceID: that.searchSaleData.CustomerSourceID,
          StartDate: that.searchSaleData.QueryDate[0],
          EndDate: that.searchSaleData.QueryDate[1],
          CreateStartDate: that.searchSaleData.CreateDate != null ? that.searchSaleData.CreateDate[0] : null,
          CreateEndDate: that.searchSaleData.CreateDate != null ? that.searchSaleData.CreateDate[1] : null,
          GoodsTypeName: that.searchSaleData.GoodsTypeName.trim(),
          GoodName: that.searchSaleData.GoodName.trim(),
          IsLargess: that.searchSaleData.IsLargess,
        };
        that.downloadLoading = true;
        API.exportSaleDetailStatement(params)
          .then((res) => {
            this.$message.success({
              message: "正在导出",
              duration: "4000",
            });
            const link = document.createElement("a");
            let blob = new Blob([res], { type: "application/octet-stream" });
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = "销售明细.xlsx"; //下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .finally(function() {
            that.downloadLoading = false;
          });
      } else {
        that.$message.error({
          message: "请选择查询时间",
          duration: 2000,
        });
      }
    },

    /** 数据导出 欠尾款 */
    downloadSaleArrearExcel() {
      var that = this;
      if (that.searchSaleArrearData.QueryDate != null) {
        let params = {
          EntityID: that.searchSaleArrearData.EntityID,
          CustomerSourceID: that.searchSaleArrearData.CustomerSourceID,
          StartDate: that.searchSaleArrearData.QueryDate[0],
          EndDate: that.searchSaleArrearData.QueryDate[1],
          CreateStartDate: that.searchSaleData.CreateDate != null ? that.searchSaleData.CreateDate[0] : null,
          CreateEndDate: that.searchSaleData.CreateDate != null ? that.searchSaleData.CreateDate[1] : null,
          GoodsTypeName: that.searchSaleArrearData.GoodsTypeName.trim(),
          GoodName: that.searchSaleArrearData.GoodName.trim(),
          IsLargess: that.searchSaleArrearData.IsLargess,
        };
        that.downloadLoading = true;
        API.exportSaleArrearDetailStatement(params)
          .then((res) => {
            this.$message.success({
              message: "正在导出",
              duration: "4000",
            });
            const link = document.createElement("a");
            let blob = new Blob([res], { type: "application/octet-stream" });
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = "补尾款明细.xlsx"; //下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .finally(function() {
            that.downloadLoading = false;
          });
      } else {
        that.$message.error({
          message: "请选择查询时间",
          duration: 2000,
        });
      }
    },
    /** 数据导出 退款 */
    downloadSaleRefundExcel() {
      var that = this;
      if (that.searchSaleRefundData.QueryDate != null) {
        let params = {
          EntityID: that.searchSaleRefundData.EntityID,
          CustomerSourceID: that.searchSaleRefundData.CustomerSourceID,
          StartDate: that.searchSaleRefundData.QueryDate[0],
          EndDate: that.searchSaleRefundData.QueryDate[1],
          CreateStartDate: that.searchSaleData.CreateDate != null ? that.searchSaleData.CreateDate[0] : null,
          CreateEndDate: that.searchSaleData.CreateDate != null ? that.searchSaleData.CreateDate[1] : null,
          GoodsTypeName: that.searchSaleRefundData.GoodsTypeName.trim(),
          GoodName: that.searchSaleRefundData.GoodName.trim(),
          IsLargess: that.searchSaleRefundData.IsLargess,
        };
        that.downloadLoading = true;
        API.exportSaleRefundDetailStatement(params)
          .then((res) => {
            this.$message.success({
              message: "正在导出",
              duration: "4000",
            });
            const link = document.createElement("a");
            let blob = new Blob([res], { type: "application/octet-stream" });
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = "退款明细.xlsx"; //下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .finally(function() {
            that.downloadLoading = false;
          });
      } else {
        that.$message.error({
          message: "请选择查询时间",
          duration: 2000,
        });
      }
    },
  },
  mounted() {
    var that = this;
    that.ArrearSaleDetailExport = permission.permission(that.$route.meta.Permission, "Report-Entity-ArrearSaleDetail-Export");
    that.RefundSaleDetailExport = permission.permission(that.$route.meta.Permission, "Report-Entity-RefundSaleDetail-Export");
    that.SaleDetailExport = permission.permission(that.$route.meta.Permission, "Report-Entity-SaleDetail-Export");
    that.getstoreEntityList();
    that.handleSaleSearch();
    that.CustomerSourceData();
    that.handleSaleArrearSearch();
    that.handleSaleRefundSearch();
  },
};
</script>

<style lang="scss">
.ReportEntitySaleDetail {
  .el-tabs--border-card {
    border: 0px !important;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0), 0 0 0px 0 rgba(0, 0, 0, 0);
  }
  .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 15px;
  }
}
</style>
