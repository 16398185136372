/**
 * 销售明细报表
 */
 import * as API from '@/api/index'

 export default {
    // 销售报表-销售
    getSaleDetailStatement: params => {
        return API.POST('api/entitySaleDetailStatement/list', params)
    },
    // 销售报表-销售欠款
    getSaleArrearDetailStatement: params => {
        return API.POST('api/entitySaleArrearDetailStatement/list', params)
    },
    // 营业报表-消耗统计
    getSaleRefundDetailStatement: params => {
        return API.POST('api/entitySaleRefundDetailStatement/list', params)
    },


  // 销售报表-销售
  exportSaleDetailStatement: params => {
    return API.exportExcel('api/entitySaleDetailStatement/excel', params)
  },
  // 销售报表-销售欠款
  exportSaleArrearDetailStatement: params => {
      return API.exportExcel('api/entitySaleArrearDetailStatement/excel', params)
  },
  // 营业报表-消耗统计
  exportSaleRefundDetailStatement: params => {
      return API.exportExcel('api/entitySaleRefundDetailStatement/excel', params)
  },


  
 }